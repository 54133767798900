<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/ja-新闻资讯banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 内容 -->
    <div class="content">
      <h1 style="font-weight: 400;">{{ dataList.title }}</h1>
      <p class="p_1">{{ dataList.date }}</p>
      <el-divider class="divI">

        <div class="icon-wechat span_1" @click="qrCodes">
          <div id="qrcode" ref="qrcode" class="qrcode" v-show="VXisShow">
            <p>即可分享页面内容</p>
            <p>打开微信扫一扫</p>
          </div>
        </div>

        <span class="icon-sina-weibo span_2" @click="weiBo"></span>
      </el-divider>
      <!-- 主要内容 -->
      <!-- 内容一 -->
      <p class="p_01" v-for="item in dataList.content_1" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>
      <!-- 第一张图片 -->
      <img :src="dataList.img_1" alt="">
      <!-- 内容二 -->
      <p class="p_01" v-for="item in dataList.content_2" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>
      <!-- 图片2 -->
      <img :src="dataList.img_2" alt="" />
      <!-- 内容三 -->
      <p class="p_01" v-for="item in dataList.content_3" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>

      <img :src="dataList.img_3" alt="" />
          <p class="p_01" v-for="item in dataList.content_4" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>

    </div>

    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 微信弹框遮罩层 -->
    <div class="shade" v-show="isShade"></div>
    <!-- 微信弹出框 -->
    <div class="diaLog" v-show="isShow_diaLog">
        <van-icon name="certificate" size="12rem" style="color: #EF3E2E;display: block;"/>
        <h3>网页链接已复制</h3>
        <p>快去微信分享给好友吧！</p>
        <el-divider content-position="right" style="width: 90%;margin:0 auto;"></el-divider>
        <p class="I" @click="know">我知道了</p>
    </div>
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/ja-关于盛泰banner.png" style="width: 100%;">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <div class="center">
      <h2>{{ dataList.title }}</h2>
      <p class="p_1">{{ dataList.date }}</p>
      <el-divider class="divI">

        <div class="icon-wechat span_1 btn" @click="copYBtn" :data-clipboard-text="urlInfo"> </div>

        <span class="icon-sina-weibo span_2" @click="weiBo"></span>
      </el-divider>
      <div class="textImg">
              <!-- 内容一 -->
      <p class="p_01" v-for="item in dataList.content_1" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>
      <!-- 第一张图片 -->
      <img :src="dataList.img_1" alt="">
      <!-- 内容二 -->
      <p class="p_01" v-for="item in dataList.content_2" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>
      <!-- 图片2 -->
      <img :src="dataList.img_2" alt="" />
      <!-- 内容三 -->
      <p class="p_01" v-for="item in dataList.content_3" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>

      <img :src="dataList.img_3" alt="" />
          <p class="p_01" v-for="item in dataList.content_4" :key="item.id" style="text-indent: 2em;">{{ item.title }}</p>

      </div>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from '../components/foot.vue'
import headers from '../components/header.vue'
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { reactive, toRefs, onMounted } from 'vue'
import QRCode from 'qrcodejs2'
import { Dialog } from 'vant';
import { useRoute } from "vue-router";
import Clipboard from 'clipboard';
export default {
  components: { foot, headers, move_foot, move_header },
    name:'jaNewsId',
  setup() {
    const route = useRoute()
    const state = reactive({
      VXisShow: false,
      dataList: {},
      isShow_diaLog:false,
      isShade:false,
      urlInfo:window.location.href
    })
    let methods = {
      weiBo(title, url, pic) {
        let param = {
          url: window.location.href,
          /*分享地址(可选)*/
          type: '',
          count: '1',
          /** 是否显示分享数，1显示(可选)*/
          appkey: '',
          /** 您申请的应用appkey,显示分享来源(可选)*/
          title: '这个内容很有趣，一起来看看吧！',
          /** 分享的文字内容(可选，默认为所在页面的title)*/
          pic: pic || '',
          /**分享图片的路径(可选)*/
          ralateUid: '',
          /**关联用户的UID，分享微博会@该用户(可选)*/
          rnd: '',
        }
        let temp = [];
        for (var p in param) {
          temp.push(p + '=' + encodeURIComponent(param[p] || ''))
        }
        var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&');
        window.open(targetUrl, 'sinaweibo', 'height=800, width=800');
      },
      qrCode() {
        let qrcode = new QRCode('qrcode', {
          width: 100,
          height: 100,
          text: window.location.href, // 二维码地址
          colorDark: "#000",
          colorLight: "#fff",
        })
      },
      qrCodes() {
        state.VXisShow = !state.VXisShow
        
      },
      copYBtn(){
        state.isShow_diaLog = true;
        state.isShade = true

      let clipboard = new Clipboard(".btn"); // 这里括号里填写上面点击事件绑定的class名
      clipboard.on("success", e => {

        clipboard.destroy();
      });
      clipboard.on("error", e => {
roy();
      });
      },
      // 
      know(){
        state.isShow_diaLog = false
        state.isShade = false
      }
    }
    onMounted(() => {
      window.scrollTo(0, 0)
      methods.qrCode()
      if (route.query.id == 1) {
        state.dataList = {
          title: '喜讯！盛泰光电何阿强上榜“最美青年技能人才”',
          date: '2022-5-12',
          img_1: require('@/assets/news/1/1.jpg'),
          content_1: [
            { id: 1, title: '2022年我们党将召开第二十次全国代表大会，中国共青团也迎来建团100周年，在以习近平同志为核心的党中央坚强领导下，广大青年听党号令、跟着党奋斗，在经济发展、乡村振兴、创新创业、社会服务等领域履职尽责、砥砺笃行，用实际行动践行了“请党放心、强国有我”的青春誓言，彰显了新时代中国青年的良好风貌，在我们的身边有这么一群青年，他们技艺超群，他们用现代化理论知识成为引领大足各行业的优秀技能劳动者！他们就是“最美青年技能人才”' },
            { id: 2, title: '很荣幸，盛泰光电何阿强同志上榜大足区“最美青年”优秀事迹展示活动第四期“最美青年技能人才”' },
            { id: 3, title: '何阿强，男，中共党员，现任盛泰光电科技股份有限公司工程经理。' },
            { id: 4, title: '自2017年7月加入江西盛泰光学有限公司以来，他孜孜不倦、锲而不舍磨练自己的技能之“剑”，充分发挥技能带头人作用。2019年3—5月，他远赴印度，协助公司高层完成印度分公司的前期测绘、规划及装修工作；2019年7月，他参与公司新园区“盛泰科技企业孵化园”的规划建设，负责图纸绘制，设计对接，进度跟进等工作；2021年6月—12月，主导产业园到盛泰孵化园的产能搬迁工作，协调公司内部生产计划及外部搬迁工作，保质保量的完成了整体产能转移，为公司大量生产高精尖产品做好铺垫。' },
            { id: 5, title: '该同志在日常工作中坚持贯彻IE思维，降本增效、精益求精。协同设计院完成83000㎡的现代化园区设计工作；协同顾问团队完成20000㎡百级/千级恒温恒湿车间设计、建设工作；完成16条智能化SMT贴装线体建设及12条COB封装线体的智能化改造；跟进智能AOI设备、自动机械手等百余台设备的自动化导入；主导并制定各类型产品的标准生产工艺；全厂智能化设备的选型评估，导入等相关工作。利用专业知识合理规划生产车间与线体，对公司投入生产做出卓越贡献。' }
          ]
        }
      } else if (route.query.id == 2) {
        state.dataList = {
          title: '杨烈常委一行莅临我司调研基层社会治理和平安建设工作',
          date: '2022-2-21',
          img_1: require('@/assets/news/2/1.jpg'),
          img_2: require('@/assets/news/2/2.jpg'),
          img_3: require('@/assets/news/2/3.jpg'),
          content_1: [
            { id: 1, title: '2022年2月21日，区委常委、政法委书记杨烈，区委政法委常务副书记王安德 ，区信访办党组书记、主任梅兴华，经开区信访接待中心党支部书记、主任黄东海一行来我司就基层社会治理和平安建设工作开展专题调研。公司副总经理孙爱国接待并陪同调研。' },
          ],
          content_2: [
            { id: 1, title: '孙总详细介绍了我司关于基层社会治理、平安建设、企业安全生产、治安和警民协作政法等工作开展情况。调研组对我司取得的成绩给予了充分的肯定。' }
          ],
          content_3: [
            { id: 1, title: '杨烈指出，推动项目和企业春节后复工复产，事关一季度实现“开门红”、“开门稳”，事关经济社会高质量发展。要在做好安全生产、疫情防控等基础工作的前提下，加快复工复产，尽快释放产能，努力实现生产上规模、上水平。各企业要切实履行好疫情防控主体责任，严格落实常态化疫情防控措施，做到疫情防控和生产经营两不误。各相关部门要抢先机、抓早字、争主动，下好先手棋，主动靠前服务，全力纾困解难，多措并举助力项目、企业复工复产，稳企业、增动能、促发展，确保全年各项工作高点起步、精彩开局。' }
          ],

        }
      } else if (route.query.id == 3) {
        state.dataList = {
          title: '以科技加持，以可靠助力，盛泰公司可靠性实验分析中心剪彩仪式圆满举行',
          date: '2022-1-12',
          img_1: require('@/assets/news/3/1.jpg'),
          img_2: require('@/assets/news/3/2.jpg'),
          img_3: require('@/assets/news/3/3.jpg'),
          content_1: [
            { id: 1, title: '2022年1月12日上午10：00，盛泰光电科技股份有限公司可靠性实验分析中心正式启用剪彩仪式在公司顺利举行。' },
            { id: 2, title: '传音公司邹卓先生、李金智先生莅临现场，盛泰光电科技股份有限公司董事长赵伟先生，总经理苏启雄先生（视频连线）、副总经理张伟先生、公司员工代表等40余人齐聚一堂，共同见证了具有里程碑意义的重要时刻。董事长赵伟、总经理苏启雄、传音公司邹卓先生分别致辞表达了祝贺。' },
            { id: 3, title: '传音研发公司为盛泰颁发“最具价值合作伙伴”奖杯，本次颁奖代表着传音公司对我司产品和团队的认可和的信任，希望双方的友谊蒸蒸日上，未来的成就像火红的丝绸般夺目辉煌！' }
          ],
          content_2: [
            { id: 1, title: '1. 可靠性试验：是指通过试验测定和验证产品的可靠性。研究在有限的样本、时间和使用费用下，找出产品薄弱环节。可靠性试验是为了解、评价、分析和提高产品的可靠性而进行的各种试验的总称。产品在规定的条件下、规定的时间内完成规定功能的能力。' },
            { id: 2, title: '2. 失效分析：协助客户投诉、试产新项目及制程产品异常分析并找出真正原因，快速应对产品异常故障，促进产品良率的持续改善' },
            { id: 3, title: '3. 仪校：建立完善的监视与测量装置控制程序，规范处理对测量仪器的维护、保养及校正，维护测量仪器的准确性，确保产品检测符合要求。' }
          ],
          content_3: [
            { id: 1, title: '此次可靠性实验分析中心剪彩仪式的举行，标志着重庆盛泰光电科技股份有限公司步入发展新征程、开启发展新篇章。' },
            { id: 2, title: '盛泰光电秉承“立足光电行业、成为国际一流品牌客户的最佳选择”的企业使命，践行“以客户为本、以品质为核心、精细化作基础、靠技术和研发永续发展”的行为准则，以技术创新为基础，持续为客户创造更多价值，为构建一个安全、智能、便捷、高效的社会而不懈努力。' }
          ],

        }
      } else if (route.query.id == 4) {
        state.dataList = {
          title: '重庆新闻联播到我司进行专题采访',
          date: '2021-11-05',
          img_1: require('@/assets/news/4/1.jpg'),
          img_2: require('@/assets/news/4/2.jpg'),
          content_1: [
            { id: 1, title: '2021年11月5日，重庆新闻联播专题采访盛泰光电科技股份有限公司。' },
            { id: 2, title: '公司人力资源中心&行政部副总经理孙爱国带领栏目组一行分别参观了车间、展厅，同时介绍了盛泰的发展历程、生产流程、高质量经营、未来展望以及新厂房在建情况。' }
          ],
          content_2: [
            { id: 1, title: '随后双方开展座谈会，深入了解公司的发展情况。盛泰公司成立初期产值不到400万，在政府提供的供应链金融服务支持下，政府“代采代购”材料，极大的缓解了公司资金压力，产能快速扩大，今年产值增加到近40亿。' },
            { id: 2, title: '重庆新闻联播本次到盛泰光电进行实地采访，是对盛泰公司3年成长的肯定，同时对提升公司在光电行业内的影响力和社会知名度起到了促进作用。' },
            { id: 3, title: '未来，盛泰光电将继续秉承“立足光电行业、成为国际一流品牌客户的最佳选择”的企业使命，践行“以客户为本、以品质为核心、精细化作基础、靠技术和研发永续发展”的行为准则，以技术创新为基础，持续为客户创造更多价值，为构建一个安全、智能、便捷、高效的社会而不懈努力。' },
          ],
        }
      } else if (route.query.id == 5) {
        state.dataList = {
          title: '利剑出刃，2020年营收约30亿斩获2021重庆民营、制造双百强！',
          date: '2021-10-09',
          img_1: require('@/assets/news/5/1.jpg'),
          img_2: require('@/assets/news/5/2.jpg'),
          img_3: require('@/assets/news/5/3.jpg'),
          content_1: [
            { id: 1, title: '2021年10月9日，2021重庆民营企业100强峰会在渝举行。会上，重庆市工商联发布了2021重庆民营企业100强、制造业100强、科技创新指数100强榜单，重庆盛泰光电公司斩获民营企业、制造业双百强。市委常委、市委统战部部长李静出席峰会并讲话，副市长陈金山为上榜企业代表颁发证书。' }
          ],
          content_3: [
            { id: 1, title: ' 公司不仅建立了覆盖华东、华南、华中的销售网络，产品还远销海外，包含北美洲、南美洲、亚洲、非洲等地区和国家。      公司产品广泛应用于OPPO、vivo、传音、联想、海信、华为等手机产品和虹膜识别、车载、医疗、3Dtof、手表、笔记本电脑等非手机类产品，以及提供AI视觉解决方。有一流的客户服务，强大的研发资源和制造能力。' }
          ],
        }
      } else if (route.query.id == 6) {
        state.dataList = {
          title: '赋能发展，砥砺前行，大足区区委书记陈一清调研重庆盛泰光电有限公司！',
          date: '2021-10-09',
          img_1: require('@/assets/news/6/1.jpg'),
          img_2: require('@/assets/news/6/2.jpg'),
          content_1: [
            { id: 1, title: '2021年10月9日，大足区区委书记陈一清前往双桥经开区调研，区委副书记、代区长、双桥经开区党工委书记、管委会主任徐晓勇等同行参加，盛泰光电总经理苏启雄陪同调研。' },
            { id: 2, title: '在调研中，总经理苏启雄详细介绍了公司生产经营活动情况、产品广泛运用于城市管理运行、汽车智能驾驶、通讯和医疗服务等领域。近年来，盛泰发展迅速，有效带动了配套企业集聚。' }

          ],
          content_2: [
            { id: 1, title: '区委书记陈一清参观了SMT、前段FOL、后段EOL产品生产线，听取新技术研发进展情况介绍，他强调，要深入贯彻落实习近平总书记对重庆提出的系列重要指示要求和党中央决策部署，按照全市主城都市区工作座谈会要求和区委安排，把握新机遇，树立新目标，焕发新活力，作出新贡献，加快成为全区经济高质量发展的主引擎和重要增长极。' }
          ],
        }
      } else if (route.query.id == 7) {
        state.dataList = {
          title: '出货量全球第四！盛泰光电荣登人民日报头刊！',
          date: '2021-10-09',
          img_1: require('@/assets/news/7/1.jpg'),
          img_2: require('@/assets/news/7/2.jpg'),
          content_1: [
            { id: 1, title: '抓住风口,对企业有多重要?' },
            { id: 2, title: '“风口,我们抓住了!3年内年产值从0.2亿元增长到近43亿元。在重庆市大足区,重庆盛泰光电有限公司总裁苏启雄感慨。' },
            { id: 3, title: '3年前,正是摄像头产业扩大的关键期。“我们有专利有技术,就是缺资金。想要融资,但公司体量不够,导致信贷额度低、无法扩大产能，苏启雄只能望订单兴叹。' },
            { id: 4, title: ' 一次碰面,苏启雄就跟自己的链长、大足双桥经开区财务局局长欧国枫说起了企业经营的难处。' },
            { id: 5, title: '链长是个什么职务?原来，大足区梳理出17条重点产业链,由党委和政府相关负责人担任链长,围绕产业链服务，推动产业集群发展。' },
            { id: 6, title: '企业有难事，政府要帮忙。大足区经过深人调研和反复论证,针对优势显著、前景广阔、市场订单量大、资金需求量大、融资担保物不足的科技型企业，创造性地推出“代采代购代建”供应链金融服务模式,解决此类成长型企业的发展难题。' },
            { id: 7, title: '为此,大足国有平台公司向盛泰光电投入1.85亿元资金,用于原材料和设备代采代购,并投资1.8亿元修建科技企业孵化园,为盛泰光电提供扩大产能的基础设施。部分投资转化为股权，硬件设施则由企业分期付款回购' },
          ],
          content_2: [
            { id: 1, title: '在盛泰光电车间,各类高端摄像头源源不断下线,随之而来的是订单大幅增长。透过车间玻璃窗，一排机器只有1名操作员控制。“新设备投用后，我们集以前8道工序于一体,1名员工能顶以前的32名员工。"看着玻璃后的无尘生产车间,苏启雄眼里满是骄傲“产量是以前的5倍。' },
            { id: 2, title: '“‘代采代购代建’的供应链金融服务,不是单纯给有困难的企业投钱，而是在研判产业前景和困难的前提下,为上下游企业提供类金融支持，解决企业发展遇到的困难，甚至帮助他们再上台阶。"大足区委书记陈一清介绍说。' },
            { id: 3, title: '在大足区.已有多家企业受益于这种模式。重庆佳航公司一年内扭转颓势,2020年产值突破44亿元,2021年 1-8月产值达 41.2亿元,预计年底达 60亿元。重庆德能公司主营业务收入同比增长超过8%。同时，地方国企通过投资实现健康转型。2020年,大足主要国企开投集团实现营收超过 40亿元,同比增长近1倍。' },
            { id: 4, title: '作为链长,不仅要把“代采代购代建”这样的好政策送到企业的手上，更要根据产业链需求招商引资,促进产业集群的汇聚和高质量发展。大足区政府代区长徐晓勇介绍:“在大足,引进一家企业,要带活一个链条，汇聚一个产业。”' },
          ],
          content_3: [
            { id: 1, title: '2015年,彭晖到大足高新技术产业开发区成立了施密特电梯公司.“没有上下游企业,园区里就我们一家电梯公司,物流和仓储成本也是不小的开支。”' },
            { id: 2, title: '没几天，“电梯链”链长、大足高新区管委会常务副主任罗登才找到彭晖,拿出一张纸……仔细一瞧,上边密密麻麻地手写着电梯的配套产品“现在最紧缺哪些?我去给你找。”随着电梯配套企业陆续进驻大足,彭晖心里有了底,“左邻右舍都是上下游企业,现在出货都比以前快了10多天。”' },
            { id: 3, title: '2020年,施密特公司产值7.9亿元,同比增长 14.8%。大足区总共生产电梯整机约 7100台,产业产值27.4亿元、同比增长超过 50%。' },
            { id: 4, title: '在大足,产业集聚抱团,走上产业升级、高质量发展的路子。今年 1-8月,当地五大特色产业规上工业产值达 462.8亿元、增长 27.7%。现在的彭晖,经常给外地的同行朋友打电话:“来大足吧,咱们一起把蛋糕做大。' },
            { id: 5, title: '“风口，我们抓住了！3年内年产值从0.2亿元增长到近43亿元。”在重庆市大足区，盛泰光电科技股份有限公司总裁苏启雄感慨。' }
          ]
        }
      } else if (route.query.id == 8) {
        state.dataList = {
          title: '做大做强产业集群 切实保障安全生产 区领导到双桥经开区调研重点企业检查安全生产！',
          date: '2021-09-29',
          img_1: require('@/assets/news/8/1.jpg'),
          img_2: require('@/assets/news/8/2.jpg'),
          img_3: require('@/assets/news/8/3.jpg'),
          content_1: [
            { id: 1, title: '（大足日报讯）2021年9月29日，区委副书记、代区长、双桥经开区党工委书记、管委会主任徐晓勇率队前往双桥经开区，调研重点企业，检查安全生产工作。' },
            { id: 2, title: '区委副书记罗晓春，双桥经开区管委会副主任吴励、陈建华、周树云参加。' }
          ],
          content_3: [
            { id: 1, title: '重庆盛泰光电有限公司位列2021重庆制造业100强企业第55位，主要生产各类摄像头模组，正加快上市步伐。重庆凯瑞特种车有限公司拥有市政环卫、中重型工程自卸、物流运输等三大类200多种车型研发生产能力。重庆足航钢铁有限公司专业生产碳素钢、优钢、型钢等各类钢材，是“重庆市优秀民营企业”。走进三家企业，徐晓勇详细了解生产经营、产品研发、产业链配套、安全生产等情况。他指出，要围绕经开区“3+ 1”特色产业和10个细分产业链，招核心、补链条、壮集群，依托龙头企业配套招商并做好企业服务，做大做强特色产业集群。' },
            { id: 2, title: '徐晓勇强调，要坚持统筹发展和安全，严把项目安全评估、风险评估关，切实推进安全发展、稳定发展。要强化底线思维，抓好重点时段、重点行业和重点领域的风险研判和隐患排查整治，扎实推进道路交通安全、工业厂房库房等各类安全专项整治，确保隐患整改管控到位，努力将各类安全隐患消除在萌芽状态，为人民群众欢度国庆假期营造安全稳定和谐的良好环境。' }
          ],
        }
      } else if (route.query.id == 9) {
        state.dataList = {
          title: '团市委一行莅临公司参观考察',
          date: '2022-06-07',
          img_1: require('@/assets/news/9/1.jpg'),
          img_2: require('@/assets/news/9/2.jpg'),
          content_1: [
            { id: 1, title: '2022年6月7日下午，团市委张珂一行莅临公司考察调研，公司贺经理陪同调研。领导一行依次来到公司展厅、生产车间等核心区域，了解到产品制程、公司发展、以及未来规划等相关情况，贺经理汇报了公司近年来的团委工作开展情况。' },
            { id: 2, title: '到展厅观看公司自主研发的科技产品，在贺经理的讲解下，领导一行对公司智能化设备、给予高度赞许，并希望盛泰不断提升研发实力，提高行业地位，同时张书记对公司团委工作给予了充分肯定，他们希望公司团委要继续做好服务青年、服务企业、服务社会等工作。' }
          ],
          content_3: [
            { id: 1, title: '调研人员' },
            { id: 2, title: '张珂共青团重庆市委副书记' },
            { id: 3, title: '陈雪重庆市青年创新创业服务中心党支部书记主任' },
            { id: 4, title: '郭伟重庆市青年创新创业服务中心副主任' },
            { id: 5, title: '梁琨重庆市青年创新创业服务中心综合科副科长' },
          ],
        }
      }else if(route.query.id == 10){
         state.dataList = {
          title: '盛泰光电进入“中国创翼”创新创业大赛重庆赛区制造业专场10强',
          date: '2022-06-10',
          img_1: require('@/assets/news/10/2.jpg'),
          img_2: require('@/assets/news/10/1.jpg'),
          img_3: require('@/assets/news/10/3.jpg'),
          content_1: [
            { id: 1, title: '第五届“中国创翼"创业创新大赛重庆市选拔赛（主体赛制造业组）暨重庆市第六届“渝创渝新"创业创新大赛市级专项初赛在江北区顺利举行。' },
            { id: 2, title: '比赛期间，30个优秀项目通过精彩纷呈的比拼，角逐出了前10强进入市级决赛。' }
          ],

          content_3: [
            { id: 1, title: '从参赛项目来看，本次大赛呈现出高精尖的特点，现场，他们的精彩角逐，展现出了重庆新制造业的新气象。' },
            { id: 2, title: '由盛泰光电科技股份有限公司带来的“基于微型摄像头模组Flip chip封装技术研究与应用"项目也备受瞩目，该项目负责人周锋说:“我们的微型摄像头，最小零部件的制造精度+/-5微米，在全国同行业来说也处于第一梯队。"周峰表示，这也是公司第一次参加类似比赛，主要目的是希望能加强沟通，展示产品，相互学习，同时也把企业的新技术介绍给更多同行，实现科技企业的责任担当。' },
            { id: 3, title: '该项目负责人袁博说:“我们参加本次大赛，主要是想看看新制造业的同行们目前的技术水平和技术的发展趋势。"袁博说，希望人社部门也多多搭建这样的平台，给科技企业更多的交流机会。' },
          ],
        }
      }else if(route.query.id == 11){
           state.dataList = {
          title: '盛泰资讯|成都市新津区税务局和商务会展局一行到访',
          date: '2022-06-25',
          img_3: require('@/assets/news/11/1.jpg'),
 
          content_1: [
            { id: 1, title: '6月23日下午，新津区税务局和商务会展局一行召开了“高端微型摄像头高科技"驱动高质量发展座谈交流会，盛泰董事长赵伟先生出席了交流会议。' },
            { id: 2, title: '成都市新津区税务局和商务会展局代表团参观了盛泰展厅、盛泰可靠性实验分析中心，SMT车间、EOL车间并进行座谈交流。赵伟先生对来访客人表示热烈欢迎，介绍了公司在“高端微型摄像头"领域的发展情况和未来的战略规划。' },
 
          ],
        }
      }else if(route.query.id == 12){
         state.dataList = {
          title: '盛泰资讯|大足区人大常委机关离退休老干部一行莅临盛泰公司参观',
          date: '2022-06-16',
          img_1: require('@/assets/news/12/1.jpg'),
          img_2: require('@/assets/news/12/2.jpg'),
          content_1: [
            { id: 1, title: '2022年6月16日上午10点，大足区人大常委会机关离退休老干部在双桥经开区管委会周树云书记陪同下莅临盛泰参观，盛泰公司副总孙爱国先生陪同介绍。离退休老干部一行参观了公司展厅、生产车间和可靠性实验室，孙爱国副总介绍盛泰光公司发展历程、管理制度、未来发展规划，大家亲身感受着近年来大足双桥经开区高速发展以及经济腾飞带来的巨大变化。' },
         ],
          content_3: [
            { id: 1, title: '盛泰光电将着手从提升产品品质、研发新产品、增强专利、车间智能化和数字化、提高销售量等方面提升实力，努力提升在国内和全球行业中的影响力。老干部们希望盛泰在区委区政府的坚强领导下，攻坚克难，发挥自身经验优势，取得更多的成果，继续为地方经济发展和当地居民提供更多就业机会，为美丽大足幸福大足作出新的贡献。' },
          ],
        }
      }else if(route.query.id == 13){
         state.dataList = {
          title: '盛泰资讯|盛泰光电成功跻身“2021重庆制造业民营企业100强”第28名',
          date: '2022-07-05',
          img_1: require('@/assets/news/13/1.jpg'),
          content_1: [
            {id:1,title:'去年10月，盛泰光电科技股份有限公司成功跻身“2021重庆制造业民营企业100强"第28名。'},
            {id:2,title:`如今，盛泰光电每月生产摄像头模组已超过2500多万颗，全球市场占有率达6.5%。大足区经信委相关负责人介绍说，"按照’一条产业链、一套领导体系、一个专家团队，一个工作方案，一个支持政策’的方针，我们围绕现代五金，农机装备等18条先进制造业产业链逐一制定实施方案，研究产业链全景图、发展路线图、升级重点工程施工图，集链成群，释放产业集聚效应。"发挥由区领导担任产业链“链长”，龙头企业担任产业链“链主”的“双链”机制，加速产链和创新链的深度融合，推动产链高度细分，从研发，生产，流通，营销各环节提升价值链。`},
        ],
          content_3: [
         ],
        }
      }else if(route.query.id == 14){
         state.dataList = {
          title: '2022大足区新任处级领导干部一行参观我司',
          date: '2022-07-07',
          img_1: require('@/assets/news/14/1.jpg'),
          img_2: require('@/assets/news/14/2.jpg'),
          content_1: [
            {id:1,title:'2022年7月7日，大足区新仟处级领导干部一行参观我司，盛泰光电科技股份有限公司公共关系部贺毅经理陪同接待，领导干部一行依次来到公司展厅，生产车间和可靠性实验分析中心等核心区域，了解到产品制程、公司发展、以及未来规划等相关情况，在展厅观摩了公司自主研发的科技产品，在贺经理的讲解下，领导一行对公司智能化设备，给予高度赞许，给予了充分肯定。'},
            {id:2,title:'盛泰实验室是集产品失效异常分析，仪器校准，RA可靠性测试为一体的全方位综合实验室.发现产品在设计，材料和工艺等方面的各种缺陷，经分析和改进，使产品可靠性逐步得到增长，最终达到预定的可靠性水平。'},
            {id:3,title:'盛泰光电将继续优化营商环境，提升产品品质，从战略和全局高度出发，增强工作的责任感、使命感和紧迫感，为全方位推动经济社会高质量发展提供坚实支撑。'},
        ],
          content_3: [
         ],
        }
      }else if(route.query.id == 15){
         state.dataList = {
          title: '盛泰光电获“川渝聚力，创汇大足”创业创新大赛决赛第一名',
          date: '2022-07-13',
          img_1: require('@/assets/news/15/1.jpg'),
          img_3: require('@/assets/news/15/2.jpg'),
          content_1: [
            {id:1,title:'7月13日，以“川渝聚力 创汇大足”为主题的2022年大足区创业创新大赛决赛在大足区举行。'},
            {id:2,title:'本次大赛由重庆市大足区人民政府主办，四川省攀枝花市、泸州市、遂宁市、内江市、乐山市、宜宾市、都江堰市和重庆市大足区、荣昌区、潼南区、铜梁区、忠县等川渝“四区八市一县”人力社保局联合承办，旨在加快推进成渝地区双城经济圈建设，协同川渝“四区八市一县”实现一体化融合发展，以创业带动就业，实现更加充分更高质量就业。'},
            {id:3,title:'本次大赛参赛项目数创新高，“四区八市一县”共有176个项目报名参赛，其中16个项目进入决赛。参赛项目行业分布广泛，覆盖农业、贸易服务、文化旅游、劳务品牌等五大类别。参赛人员类型众多，包括高校学生、退役军人、返乡创业农民工等各类创业群体。此次大赛决赛采用“6+5”现场路演的方式进行，即6分钟现场陈述、5分钟评委问答，通过专家评委现场打分，评选出一等奖、二等奖、三等奖及优胜奖。'},
        ],
          content_3: [
            {id:1,title:'经过激烈的角逐，大足区的“基于微型摄像头模组 Flip chip 封装技术研究与应用”项目获得一等奖，大足区的“齿轨山地列车”项目与忠县的“中国粮”项目获得二等奖，潼南区的“呵护生命之源——污水治理”项目、泸州市的“焊接土工格栅一体化服务商”项目、铜梁区的“艾草全产业服务平台”项目获得三等奖，其他项目分获优胜奖。'},
            {id:2,title:'比赛结束后进行了颁奖和川渝“四区八市一县”协同创业服务导师库揭牌、导师定向辅导签约等仪式。重庆市人力社保局、大足区人民政府、川渝“四区八市一县”人力社保局有关领导出席了仪式，并为获奖项目代表颁奖。'},
            {id:3,title:'大足区人力社保局相关负责人表示，本次大赛对于成渝双城经济圈地区来说，不仅是一场创业创新竞赛，更是一场创业创新交流盛会。通过本次活动将逐步建立起川渝“四区八市一县”创业创新服务协同机制，纵深推进成渝地区双城经济圈建设，从而实现共建创业服务平台、共促创业人才交流、共享创业导师资源。'},
         ],
        }
      }else if(route.query.id == 16){
         state.dataList = {
          title: '第六届“渝创渝新”创业创新大赛决赛我司项目荣获大赛制造业组三等奖',
          date: '2022-07-20',
          img_1: require('@/assets/news/16/1.jpg'),
          img_2: require('@/assets/news/16/2.jpg'),
          content_1: [
            {id:1,title:'7月20日，第五届“中国创翼”创业创新大赛重庆市选拔赛暨重庆市第六届“渝创渝新”创业创新大赛决赛颁奖仪式举行。13个项目在决赛中脱颖而出，将代表重庆征战第五届“中国创翼”创业创新大赛。本届大赛以“近悦远来·渝创渝新--创响新时代 共圆中国梦”为主题。按照人社部统一部署，设置了制造业、服务业、劳务品牌、乡村振兴、青年创意5个赛道，共挖掘1666个优质创业项目参赛。最终，42个优质创业项目脱颖而出，进入本次市级决赛暨“中国创翼”选拔赛。'},
            {id:2,title:'参加决赛的42个项目涉及互联网TMT、人工智能、新材料新能源、医疗健康、现代服务业、现代农业等领域，参赛选手覆盖高校毕业生、退役军人、返乡农民工、海外留学生等群体。经过激烈比拼，13个项目脱颖而出，我司项目“基于微型摄像头模组Flip chip封装技术研究与应用项目”获得三等奖。'},
            {id:3,title:' 据介绍，重庆市人力社保局以“渝创渝新”创业服务品牌为引领，通过实施政策助创、雏鹰培育、金融助力、服务助航、会员联动、文化厚植六大行动，让创业者们在创业路上有保障、有能力、有资金、有场地、有联盟、有平台。'},
        ],
          content_3: [
         ],
        }
      }else if(route.query.id == 17){
         state.dataList = {
          title: '江西盛泰与英恒达成战略合作，共同推进中国智能汽车产业链发展',
          date: '2022-08-17',
          img_1: require('@/assets/news/17/1.jpg'),
          img_3: require('@/assets/news/17/2.png'),
          content_1: [
            {id:1,title:'8月12日，江西盛泰精密光学有限公司（下称“盛泰”）二期车载智能工厂投产，并与上海英恒电子有限公司签订战略合作协议。'},
        ],
          content_2: [
            {id:1,title:"江西盛泰二期车载智能工厂投产暨盛泰&英恒战略合作签约仪式。分宜县县长谢淘，副县长李建军、黄燕飞，分宜县工业园区党工委副书记、纪工委书记赵伍根，上海英恒电子有限公司董事长陆颖鸣、副总经理秦晨，江西盛泰董事长赵伟、CEO苏启雄、总经理李响林、副总经理赵龙效等出席。"},
            {id:2,title:"根据协议，双方将在车载产品和和智能汽车视频等方面展开合作，打造可持续发展的战略合作伙伴关系。盛泰成为英恒集团车载产品战略合作伙伴，英恒旗下部分产品将使用盛泰提供的车载摄像头等产品。"},
            {id:3,title:"据悉，盛泰光电作为领先的摄像头模组制造企业，手机摄像头模组出货量位居全球第五，并已从手机摄像头领域延伸至车载摄像头。"},
         ],
          content_3: [
            {id:1,title:" Yole披露的车载路线图也显示，伴随自动驾驶程度的提升，车载摄像头数量将持续增加。预计到2024年，单车摄像头数量将达到11颗，未来市场规模也随之增长。从车载摄像头细分销量来看，到2025年全球车载摄像头全部数量为1.68亿颗，其中前视摄像头、后视摄像头和环视摄像头销量分别达到约5200万颗、6500万颗和2400万颗。从车载摄像头整体渗透率来看，到2025年前视摄像头、后视摄像头和环视摄像头在新车装配渗透率将分别达到55%、69%和26%。"},
            {id:2,title:"对江西盛泰来说，车载产品和智能汽车视频解决方案是其未来主要发展方向。在智能座舱领域，其提供DMS、OMS、DVR、娱乐摄像模组等产品；在自动驾驶/辅助驾驶领域，有1M/2M-360环视模组、2M ADAS前视一体机、8M ADAS等系列产品。其已向比亚迪、广汽、沃尔沃等车企大规模量产交付，并与长城、小鹏、蔚来、丰田等车企深入合作。二期车载智能工厂的投产，将使其有更大的产能满足客户需求。"},
            {id:3,title:"除了扩产，与英恒签约战略合作也是江西盛泰在车载领域延伸、拓展智能汽车视频解决方案的重要里程碑。"},
            {id:4,title:"英恒创立于2001年，专注于汽车电子应用市场的方案解决与技术开发服务，解决方案涵盖汽车电子部件三大主要类别（车身控制、安全及动力传动），以及新能源汽车电池管理系统、整车控制器及电机控制器三大核心技术。英恒还与地平线、英飞凌等半导体器件供应商达成良好战略合作。"},
            {id:5,title:"在签约现场，英恒副总经理秦晨展示了GDCU34B自动驾驶域控制单元，面向L2+的领航辅助驾驶应用开发，支持接入1路8MP的摄像头、9路2MP的摄像头、5路毫米波雷达以及12路超声波传感器的接入。该控制单元提供强大的视觉感知功能，支持基于深度学习的图像检测、分类、像素级分割等功能，也支持对H.264和H.265视频格式的高效编码，可实现多通道AI计算和多通道数字视频录像，并用于自动驾驶相关产品功能的开发。"},
         ],
        }
      }else if(route.query.id == 18){
        state.dataList = {
          title:"国际瑜伽日2022 | IDY2022 Curtain Raiser events continue in South China",
          date:"2022-06-17",
          img_1: require('@/assets/news/18/1.png'),
          img_2: require('@/assets/news/18/2.png'),
          content_1:[
            {id:1,title:"盛泰集团（Sensetek ）瑜伽俱乐部组织了一场迎接#国际瑜伽日2022# 的活动。以下视频图片由盛泰集团提供。"}
          ],
          content_2:[
            {id:1,title:"国际瑜伽日旨在提高全世界对于练习瑜伽可以带来诸多益处的意识。瑜伽体现了心灵和身体的统一、思想与行动的统一，这种整体方法有益于我们的健康和福祉。瑜伽不仅仅是锻炼，它是一种发现自己、世界与自然三者合为一体的方式。"},
            {id:2,title:"盛泰集团在6月的国际瑜伽日，组织一场迎接“世界瑜伽日”的活动，旨在让员工，放松身心，感受瑜伽的带给身体和心灵的福祉，远离都市疾病困扰。"}
          ]
        }
      }else if(route.query.id == 0){
        state.dataList = {
          title:"盛泰有约·家庭趣欢享||家庭开放日活动",
          date:"",
          img_1: require('@/assets/news/0/1.png'),
          img_2: require('@/assets/news/0/2.png'),
          img_3: require('@/assets/news/0/3.png'),
          content_1:[
            {id:1,title:"盛泰光电迎来首个家庭开放日活动，在这新风和暖的下午，彼此与家人轻松玩乐，融洽团队和各位家人关系，增进对公司的了解，创建和谐发展的公司氛围，与家人们相聚一起，共享在这里的心路历程，与爱同在，共享美好时光。公司过往的成长依赖于所有员工的支持，更离不开所有员工家人的无私关怀每天我们从一个家来到另一个“家”，在工作中不断地践行自己最初的梦想。我们希望在未来的发展中，公司与所有的员工和家人一起共同成长。"}
          ],
          content_2:[
            {id:1,title:" 公司一直提倡工作与健康并行，为宣扬强身健体理念，本次活动为大家带来了和谐瑜伽秀表演。通过呼吸和体位的结合，冥想并集中意识，让身体和心灵都得到锻炼。可调整人体亚健康状态，矫正体形、体态的同时还能保持心境平和，苏总也加入了本次瑜伽秀表演，和大家一起体验瑜伽的乐趣。"},
        ],
          content_3:[
            {id:1,title:"热爱游戏几乎是每个孩子的天性。这一天，我们和孩子一起有了一次深度陪伴。家长和孩子一起度过一段美好的亲子时光。"},
            {id:2,title:"经过一个多小时的游戏时间大家都稍显疲惫，我们为大家准备了各种面包糕点，时令水果、特色饮品供大家品尝，让大家在欢乐之余，稍作休息。"},
            {id:3,title:"为感谢员工们的付出，公司特意为入职盛泰光电五周年、十周年员工定制纪念币，由总经理苏启雄先生颁发，并向他们表示祝贺和感谢，感谢他们对盛泰的发展做出卓越贡献和认同。"},
        ]
        }
      } else if (route.query.id == 20) {
        state.dataList = {
          title: "盛泰资讯|盛泰光电捐资助学，情暖高升莘莘学子",
          date: "2022-08-30",
          img_1: require('@/assets/news/20/1.jpg'),
          img_2: require('@/assets/news/20/2.jpg'),
          img_3: require('@/assets/news/20/3.jpg'),
          content_1: [
            { id: 1, title: "8月24日，在高升镇举行的2022年秋季捐资助学仪式上，盛泰光电科技股份有限公司和重庆双足惠科技有限公司两家企业为该镇52名贫困学子共捐助6万元，用以帮助他们顺利完成学业，助力乡村人才振兴。" }
          ],
          content_2: [
            { id: 1, title: "为确保捐赠物资能帮助到贫困学子，我司负责人在高升镇、驻该镇乡村振兴工作队相关人员陪同下，前往旭光村5组瞿锐和胜光村2组袁莹植两名贫困学生家中进行了实地走访，详细了解他们的家庭、学习以及生活情况，对他们在逆境中表现出来的积极向上、不屈不挠的拼搏精神给予充分肯定，将以实际行动帮助贫困学子渡过难关，顺利完成学业。" },
            { id: 2, title: "捐助仪式上，高升镇负责人对捐助企业表示感谢，他说：“青少年学生是十分宝贵的人才资源，是民族的希望，是祖国的未来。帮助贫困学生顺利完成学业，关系到一代人的成长，其意义重大而深远。”" },
          ],
          content_3: [
            { id: 1, title: "我司负责人代表捐助企业做了发言，企业离不开当地政府和群众的全力支持，捐资助学只是盛泰光电回报社会的一个小小举措，帮助困难学生顺利完成学业是企业应尽的义务。孩子们的健康成长是社会文明进步的重要标志，是乡村人才振兴、构建和谐社会的必然要求。社会多一分关爱，孩子就多一分信心，未来就多一分希望。" },
            { id: 2, title: "受资助的贫困学生代表对政府、企业和乡村振兴工作队表示衷心感谢，在未来的人生道路上，他们会通过顽强拼搏、跨过每道坎坷、超越每次挫折，刻苦学习，永远怀着一颗感恩的心，用青春与热情回报社会。" },
         ]
        }
      } else if (route.query.id == 19) {
        state.dataList = {
          title: "盛泰资讯|大足区经信委开展复工复产调研工作，访企业、听心声、问需求、话发展",
          date: "2022-08-30",
          img_1: require('@/assets/news/19/1.jpg'),
          content_1: [
            { id: 1, title: "2022年8月30日，大足区经信委覃勇主任一行探访盛泰光电，开展“受新冠疫情、高温天气、有序用电的复工复产调研服务工作”，深入了解企业复工复产期间需协调帮助的困难，帮助并指导企业用好“企业吹哨·部门报到”平台，企业通过平台反映当前的突出问题和瓶颈问题，政府做到“一事一议”有效回应企业诉求，确保电力恢复后产线开得起、产品产得出，把时间抢回来，把工期赶上来，把损失补回来。" },
            {id:2,title:"面对极端高温天气、有序用电限制、新冠疫情严峻等各项困难，企业响应政府号召让电于民，为灾区捐赠物资，政企一心共度难关，各级政府已出台各类惠企助企政策措施能，帮助企业解决燃眉之急."}
          ],

        }
      }else if (route.query.id == 21) {
        state.dataList = {
          title: "盛泰资讯|吉林大学重庆研究院到盛泰光科走访交流",
          date: "2022-09-06",
          img_1: require('@/assets/news/21/1.jpg'),
          img_2: require('@/assets/news/21/2.jpg'),
          content_1: [
            { id: 1, title: "为加强学校与用人单位的交流，积极为学生拓展优质的创业实践机会和更多的就业岗位，促进校企协同创新育人，不断实现人才培养与产业需求的无缝对接，9月2日上午，大足区科技局党组成员、科创中心主任唐政先生带领吉林大学重庆研究院罗怀勇副院长 、应用推广部朱雄主任来到盛泰光科进行走访调研。" },
          ],
          content_2: [
            { id: 1, title: "盛泰负责人带领来访领导参观了盛泰公司展厅、生产车间和可靠性实验分析中心，详细介绍了公司生产摄像头模组系列产品，唐政主任讲解了产学研政策，与吉林大学重庆研究院进行产学研合作交流，希望公司与学校在人才招聘、协同育人、就业创业实习实践基地等方面加强联系和合作。" },
            { id: 2, title: "学院通过与企业的走访交流，了解新形势下企业的用人需求，毕业生在企业的工作、生活和职业发展等情况，为未来校企双方在科研对接、人才培养、毕业生推荐、就业创业实践等方面深度合作打下良好的基础。校企双方将继续深化“走出去、请进来、深合作”的合作模式，积极为毕业生拓展高质量就业岗位，多渠道打造用人单位与毕业生的对接平台，充分挖掘学生潜能，创造广阔平台，力求做到“人人”成才，实现产、学、研的良性互动与校、企、生的“多方共赢”。" },
          ],

        }
      }else if (route.query.id == 22) {
        state.dataList = {
          title: "盛泰资讯|大足区组织部副部长、老干局局长张玛莉一行领导莅临我司调研",
          date: "2022-09-27",
          img_1: require('@/assets/news/22/1.jpg'),
          img_2: require('@/assets/news/22/2.jpg'),
          img_3: require('@/assets/news/22/3.jpg'),
          content_1: [
            { id: 1, title: "2022年9月26日下午，大足区组织部副部长、老干局局长张玛莉，双桥非公党建尹东洋主任及园区相关领导莅临盛泰光电调研，我司孙爱国副总经理主持接待。" },
            {id:2,title:"张局长一行认真听取了盛泰公司简介、成长经历、产品类型、终端应用等介绍，并对生产现场展开实地探访，了解生产全制程工艺及主要生产设备介绍，高端、精密产品的生产过程及严苛要求。各位领导对公司的发展给予肯定，鼓励公司克服外界因素，持续做强做大。"}
          ],
          content_2:[
          { id: 1, title: "盛泰公司的“党群活动中心”位于职工之家三楼，总面积约1000㎡，在上级党委、公司领导的大力支持下，于2022年年初完成整体装修工作，是集党建工作、员工活动于一体的活动中心。" },
          { id: 2, title: "领导一行在“党群活动中心”进行参观，检查指导党建工作，并给予高度肯定评价，表示上级党组织将不遗余力的从硬件、软件支持企业党建工作发展，助力企业持续提高党建工作质量及水平；同时指出企业开展党建工作，要让“扎根于基层，服务好基层”的理念贯彻始终，充分发挥党员先进性，有计划、有组织的展开党建工作，让更多的人参与进来，感受到党的温暖；也指示党建工作不能马虎，不搞四风之事，坚持以实事求是为原则，基层党建工作要带好方向，要仔细听，也要仔细看，了解民生意愿，积极落实处理。" },
          ],
          content_3:[
          { id: 1, title: "各位领导对公司发展的肯定，激励我们继续努力，对党建工作的叮嘱也督促着我们继续深化学习。政企良好的互动更利于公司听取意见，落到实处，助力公司变得更美好，发展壮大。" },
         ]

        }
      }else if (route.query.id == 23) {
        state.dataList = {
          title: "盛泰资讯|盛泰光电上榜2022重庆制造业企业100强",
          date: "2022-09-28",
          img_1: require('@/assets/news/23/1.jpg'),
          img_2: require('@/assets/news/23/2.jpg'),
          content_1: [
            { id: 1, title: "9月26日，由市经信委、重庆市企业联合会（企业家协会）主办的，2021年度重庆市优秀企业家表彰会暨2022重庆百强企业发布会举行，会上公布了2021年度全市40名优秀企业家及2022年度全市百强企业名单，盛泰光电科技股份有限公司上榜2022重庆制造业企业百强名单，位列第53位。" },
          ],
          content_1: [
            { id: 1, title: "近年来，盛泰光电科技股份有限公司已经快速发展成为摄像模组国内第四大企业，在车载、医疗、智能家居等非手机行业也有所突破。盛泰产品有多摄摄像头、一亿像素摄像头、超广角和潜望式摄像头等，年产能达3亿颗，摄像头占全球生产额的5%，广泛应用于oppo、vivo、华为、三星等手机产品和虹膜、车载、医疗等非手机类产品。" },
            { id: 2, title: "作为一个科技创新型企业，盛泰光电拥有专利160项，发明专利42项，获得“科技型企业”、“技术创新中心”、“双百企业”、“大足区工业20强”、“知识产权优势企业”等多项荣誉。" },
            { id: 3, title: "盛泰光电在快速成长中秉承强化中国制造、壮大民族产业的初心，不断奋勇前进，实现做强、做大、走远，做百年光电企业的愿景，立足光电行业，成为国际一流品牌客户的最佳选择。" },
          ],

        }
      }else if (route.query.id == 24) {
        state.dataList = {
          title: "",
          date: "2022-08-22",
          img_1: require('@/assets/news/24/1.png'),
          img_2: require('@/assets/news/24/2.png'),
          content_1: [
            { id: 1, title: "丰富广大员工业余文化生活，大力营造“亲近自然、健康生活、快乐工作”的和谐氛围，培养和激发广大职工的兴趣爱好，盛泰光电垂钓社团举办了2022第一届“盛泰杯”钓王大赛。钓鱼比赛场地在盛泰园区的景观湖举行，来自各部门的20余名钓鱼爱好者参加了本次活动。" },
          ],
          content_2: [
            { id: 1, title: "上午七时许，随着一声令下，钓鱼比赛正式开始。只见大家尽显高手风范，一根钓竿、几卷钓线，那恰到好处的抛竿、收杆，钓线在空中划出一道道漂亮的弧线。静待一段时光之后，一尾尾鲜活的鱼儿被利落干净的收进鱼护，伴着一阵此起彼伏的叫好声，灿烂喜悦的笑容在选手们脸上不断绽放。比赛开始不到5分钟，PE杨修惠就上鱼收获了一条近3.5斤重的草鱼，以此获得了“钓鱼最快奖”。比赛经过半个小时，大家鱼获很少，故延迟了30分钟的比赛时间，陆续有人开始上鱼，一条条大青鱼接连咬钩。钓鱼高手彭常超也不甘示弱，在接下来半个小时的时间内，连杆钓上多条2斤以上的草鱼。经过一个小时的比赛，最终EOL彭常超以鱼获总重量9.8斤获得“钓鱼最重奖”。" },
          ],
          content_3: [
            { id: 1, title: "参加活动的职工们纷纷表示，垂钓比赛不仅仅是技艺和耐力的比拼，更让大家享受了垂钓的乐趣，饱览了自然风光，陶冶了情操，为今后潜心工作，带来更多动力。" },
          ],

        }
      }else if (route.query.id == 25) {
        state.dataList = {
          title: "2022年内部讲师交流会圆满举行",
          date: "2022-09-13",
          img_1: require('@/assets/news/25/1.png'),
          img_2: require('@/assets/news/25/2.png'),
          content_1: [
            { id: 1, title: "春华秋实，金风玉露，秋风送爽，岁月如诗。又是一年人间团圆日，恰逢桃李硕果教师节。师者，传道授业解惑，如春风化雨，润物无声。企业内部讲师，作为企业自生的师资力量，为企业注入传递智慧的血液。人才的发展与培养离不开公司的栽培，也离不开讲师的辛勤付出。为了感谢在公司发展中不吝赐教的园丁，9月8日下午，人力资源部组织开展了2022年内部讲师交流会，答谢师恩，活动邀请了2021年和2022年内部选聘的31位讲师以及相关领导嘉宾参与。" },
            { id: 2, title: "盛泰内部讲师总教练Tony老师在线上为所有讲师带来了教师节寄语，感谢讲师们为盛泰内部培训事业做出的贡献，也期望他们能一如既往的充当好教练的职责，为学员分享更多的知识与经验。" },
          ],
          content_2: [
            { id: 1, title: "在2021年10月以及2022年5月，人力资源部组织了TTT讲师训以及试讲考核评选，选拔出来了30余位内部讲师们，在今年教师节即将来临之际，运营部梅总监和研发部袁总监为讲师们颁发了聘书以及教师节小礼品，以此表示公司对内部讲师的寄托与感恩。" },
            { id: 2, title: " 要成为一位优秀的讲师，需要不断学习与提高自己的授课技巧，在内部讲师交流会上，大家分成3个小组以企业文化为主题进行讨论，在20分钟的时间里，需要讨论出课程的框架以及课堂中应该用到的教学技巧与方法。经过各小组激烈的讨论，选出了3位代表进行试讲，他们展示了团队合作的成果，对企业文化里面的行为准则、企业精神进行了课堂展示，让我们看到了不同的教学方法与课堂设计，也看到了各位讲师积极、用心的态度。" },
          ],
          content_3: [
            { id: 1, title: "我们一样来自远方，做着普通的工作，在外漂泊思乡，因为缘分，让我们成为同事，因为职责，让我们成为讲师，我们相聚在盛泰，同为一家人，为了共同的目标与心声，一起奋斗前行。在双节到来之际，让我们致敬所有的内部讲师，感谢他们甘愿分享自己的经验与技巧，期待大家能一直以企业讲师的身份，承担起对企业内部传播知识的责任，让盛泰大学堂栉风沐雨，顾往追新，海纳百川、蒸蒸日上，培养更多内部人才，共同打造盛泰更加美好的未来。" },
          ],
        }
      }else if (route.query.id == 26) {
        state.dataList = {
          title: "江西省人大常委会副主任张小平来江西盛泰精密光学有限公司开展调研",
          date: "2022-10-15",
          img_1: require('@/assets/news/26/1.png'),
          content_1: [
            { id: 1, title: "10月9日，省人大常委会副主任张小平来我司调研，省人大环资委主任委员吴治云，省应急厅、省工信厅、省能源局等部门有关负责同志参加调研。市领导徐鸿、赖国根、马小红、舒永忠、张晓明，县领导邹家洪、谢淘、胡瑜瑞、罗艳兵陪同调研。江西盛泰精密光学有限公司总经理李响林陪同接待。" },
            { id: 2, title: "张小平对今年以来园区和我县经济社会发展所取得的成绩予以充分肯定，对省直相关部门给予园区及企业的帮助和支持表示感谢。他强调，分宜县要深入学习贯彻党中央“疫情要防住、经济要稳住、发展要安全”重要要求，认真落实全省稳增长、防风险、保稳定、惠民生工作部署会议精神，保持“咚咚响、嗷嗷叫”的精气神，以更强的责任担当和创先争优的浓厚氛围，确保全年各项目标任务圆满收官。要坚持大抓项目、抓大项目，持续加强与上级部门沟通对接，全力以赴攻坚克难，推动项目早日投产见效，为经济社会高质量发展提供有力支撑。要守牢安全生产、疫情防控、抗旱救灾、社会稳定底线，抓紧抓实抓好稳增长、防风险、保稳定、惠民生等各项工作，以优异成绩迎接党的二十大胜利召开。县领导李建军、黄燕飞陪同调研。" },
          ],


        }
      }else if (route.query.id == 27) {
        state.dataList = {
          title: "盛泰光电第二届羽毛球比赛圆满结束",
          date: "2022-10-21",
          img_1: require('@/assets/news/27/1.png'),
          content_1: [
            { id: 1, title: "秋天如约而至，带着硕果累累的美好，球拍握在手中，带着激情肆意挥洒。通过本次羽毛球比赛，员工积极参加体育锻炼，促进身心健康，全面发展，推动企业文化的建设，让大家收获满满，有开心，有遗憾，有成长，也有了一次难忘的回忆！" },
            { id: 2, title: "根据赛制，初赛和决赛均由抽签随机决定对战双方。经过初赛和决赛后，最终孔庆祥、彭科林、杨波三人进入决赛。实力相当的三位选手在这一轮相遇，他们将网前小球、扣杀、劈杀等技巧展现的淋漓尽致，引来场边阵阵欢呼喝彩。决胜局比分一度胶着上升，经过决赛的激烈比拼，孔庆祥获得了男单比赛冠军，彭科林获得比赛亚军，杨波获得比赛季军。" },
            { id: 3, title: "女子单打比赛同样激烈精彩，三位晋级半决赛的选手为大家奉献了精彩的比赛。她们在场上不断跃起、转体、挥拍、击球，动作干净利落，比赛过程扣人心弦。最终陈欢获得了女单冠军，李晓晓获得亚军，朱黎得了季军。" },
            { id: 4, title: "激烈的单打比赛消耗了大量体力，混双比赛就不仅仅是技术的比拼，更是大家意志力的比拼，最终肖含茂和陈英虎获得季军，陈欢和程德志获得亚军，何阿强与李晓晓成为这场强强对决的最后赢家。" },
            { id: 5, title: "扣球的声音，体现盛泰人昂扬的斗志；拼搏的汗水，彰显盛泰人坚韧不拔的精神。共凝“青春之力”，增强“奋斗有我”的骨气，共赴“青春之约”；增强“功成有我”的底气，让青春绽放绚丽之花！" },
          ],


        }
      }else if (route.query.id == 28) {
        state.dataList = {
          title: "创新是第一动力 人才是第一资源",
          date: "2022-10-27",
          img_1: require('@/assets/news/28/1.png'),
          content_1: [
            { id: 1, title: "中国共产党第二十次全国代表大会于2022年10月16日在北京召开。习近平总书记代表第十九届中央委员会向党的二十大作报告。盛泰光电广大党员干部组织观看了党的二十大会议开幕式，公司上下深受鼓舞，纷纷表示将踔厉奋发、勇毅前行，在新征程上展现新作为。" },
            { id: 2, title: "盛泰光电科技股份有限公司，目前已快速发展成为摄像模组国内第四大企业，年产能达3亿颗各类摄像头，公司整合资源集中优势，再次出击新产品、新市场、新领域；研发并顺利量产了1亿像素和2亿像素等高像素摄像头、倒装工艺摄像头，车载、笔记本摄像头以及智能物联网摄像头等产品，盛泰光电产品在不断迭代的当下，保持着全球出货量份额6.5%，总产值40亿元。" },
          ],
          content_1: [
            { id: 1, title: "研发部总监谢演军介绍说，1亿、2亿像素摄像头可以极大限度地把拍摄物拍得更清晰，呈现更丰富的细节；倒装工艺让摄像头变得更小更薄，极大地满足了客户对于边框更窄、屏幕更大的极致追求；车载、笔记本摄像头以及智能物联网摄像头等产品很好地迎合了蓬勃发展的视讯会议、新能源汽车、智慧汽车、智能家居等市场需求。党的二十大报告提出要集聚力量进行原创性引领性科技攻关，坚决打赢关键核心技术攻坚战，我们有信心有决心，不断攻坚克难，插上创新和研发这对翅膀，飞出“加速度”。" },
            { id: 2, title: "党的二十大报告中提出，必须坚持科技是第一生产力、人才是第一资源、创新是第一动力，深入实施科教兴国战略、人才强国战略、创新驱动发展战略，开辟发展新领域新赛道，不断塑造发展新动能新优势，将创新放在更加重要的位置，这让公司上下倍感鼓舞、催人奋进。大家纷纷表示，将以党的二十大胜利召开为契机，以更加饱满的热情投入到产品研发创新、提升工艺技术的工作中去；将继续秉承强化中国制造、壮大民族产业的初心，不断奋勇前进，实现做强、做大、走远。" },
          ],

        }
      }else if (route.query.id == 29) {
        state.dataList = {
          title: "盛泰光电举办“凝心聚力，犇向新征程”拔河比赛圆满结束",
          date: "2022-11-15",
          img_1: require('@/assets/news/29/1.png'),
          content_1: [
            { id: 1, title: "当哨声在耳畔响起，当手臂在眼前挥下，一根绳，两队人，怀着相同的目标，朝着努力的方向，奋力而冲。" },
            { id: 2, title: "为培育“团结、互助、和谐、温馨”的工作团体，  营造活跃的企业文化氛围，引领公司员工凝心聚力，发扬团队精神，使参与人员结合集体的力量，提高团队合作和坚韧不拔的精神。公司举办了2022年盛泰光电”凝心聚力，犇向新征程“拔河比赛。" },
          ],
          content_2: [
            { id: 1, title: "比赛开始前，裁判员做赛前动员并宣读比赛规则。随后，各参赛小组按抽签顺序依次进行比赛。比赛期间，各参赛队队员脚蹬脚、肩靠肩，压低重心，卯足劲往一处使，将集体荣誉感和团队协作精神展现得淋漓尽致。赛场上加油声、呐喊声、喝彩声响成一片，整个比赛高潮迭起、紧张激烈，赛出了风格，赛出了友谊。FOL组作为今年的黑马赢得了本届的冠军，品质部组夺得了亚军，移动终端事业部研发+财务中心组获得了季军。" },
            { id: 2, title: "赛后，参赛队员纷纷表示，此次活动即放松了身心，锤炼了意志品格，也将会以此次拔河比赛为契机，把在比赛中的团结协作、争先创优、顽强拼搏的精神带入到日常工作中去，以后各项工作中再创佳绩。" },
            { id: 3, title: "一根绳，一条心，迸发的是身体的力量，拉近的是心与心的距离，此次拔河比赛充分展现了团结协作的精神，希望在接下来的工作和生活中大家都能秉持这种精神，继续凝心聚力，勇敢前行！" },
          ],

        }
      }else if (route.query.id == 30) {
        state.dataList = {
          title: "大足 盛泰光电入选国家知识产权优势企业",
          date: "2022-11-24",
          img_1: require('@/assets/news/30/1.png'),
          content_1: [
            { id: 1, title: "11月24日国家知识产权局公布了2022年新认定国家级知识产权优势企业名单，盛泰光电科技股份有限公司成功入选，榜上有名。" },
            { id: 2, title: "近年来，盛泰光电科技股份有限公司已经快速发展成为摄像模组国内第四大企业，在车载、医疗、智能家居等非手机行业也有所突破。盛泰产品有多摄摄像头、一亿像素摄像头、超广角和潜望式摄像头等，年产能达3亿颗，摄像头占全球生产额的5%，广泛应用于oppo、vivo、华为、三星等手机产品和虹膜、车载、医疗等非手机类产品。" },
          ],
          content_2: [
            { id: 1, title: "作为一个科技创新型企业，盛泰光电拥有专利160项，发明专利42项，获得“科技型企业”、“技术创新中心”、“双百企业”、“大足区工业20强”、“知识产权优势企业”等多项荣誉。" },
            { id: 2, title: "据悉，“国家知识产权优势企业”是国家知识产权局对认定企业在知识产权管理领域获得国家认可，具备自主知识产权能力，积极开展知识产权保护和运用，建立全面的知识产权管理制度和机制，具有知识产权综合实力的企业。近年来，大足区市场监督管理局（知识产权局）贯彻落实《重庆市知识产权保护和运用“十四五”规划（2021-2025年）》和《重庆市高价值发明专利质量提升行动方案（2022-2024年）》，积极发挥知识产权管理职能，引导企业提升知识产权创造、运用、保护和管理水平，着力打造知识产权强企建设方阵，并推进以价值实现为导向的成果转化。" },
            { id: 3, title: "盛泰光电在快速成长中秉承强化中国制造、壮大民族产业的初心，不断奋勇前进，实现做强、做大、走远，做百年光电企业的愿景，立足光电行业，成为国际一流品牌客户的最佳选择。" },
          ],

        }
      }else if (route.query.id == 31) {
        state.dataList = {
          title: "同心“战”疫，共克时艰|爱护宿舍环境，从你我做起",
          date: "2022-11-26",
          img_1: require('@/assets/news/31/1.png'),
          content_1: [
            { id: 1, title: " 宿舍和园区环境卫生和我们每个人都息息相关，所以我们在此倡议大家请把垃圾扔进垃圾袋或者垃圾箱里，垃圾事小，乱扔事大，您随手丢弃的一团纸巾，一块橘子皮，都会是在美丽园区的环境上的污点，就像美丽的衣衫粘上了草渣泥点，同样也给辛苦维护的的保洁人员增加额外工作。新宿舍新环境，大家注意保持，爱护家园从自己做起，让我们生活环境更加整洁美好。" },
            { id: 2, title: " “疫”字当头，同心协力，维护宿舍环境干净整洁成为你我共同的责任。我们每个人都要重视起来，做好个人防护工作，让病毒无处遁形，共同等待疫情结束！" },
          ],
        }
      }else if (route.query.id == 32) {
        state.dataList = {
          title: "青春无畏·逐梦扬威，盛泰光电2022年冬季职工篮球赛圆满结束",
          date: "2022-11-27",
          img_1: require('@/assets/news/31/1.png'),
          content_1: [
            { id: 1, title: "  “矫健身法迈步冲，盛泰男篮飒英风。”为丰富职工的业余生活，锻炼强健的体魄，增强各部门的凝聚力，激发职工的拼搏精神与竞技精神，盛泰光电于11月25日举办了“青春无畏·逐梦扬威”主题篮球赛，本次比赛有18名队员参加，通过积分制决出比赛胜负。" },
            { id: 2, title: "随着哨声响起，宣告了本次篮球赛正式拉开帷幕，红队和蓝队两支队伍对战球场，各位队员都全力以赴，积极防守，愈战愈勇，精妙的传球、漂亮的配合、飘逸的投篮、灵活的脚步，无一不收获了场下阵阵欢呼。赛场上的运动健儿你追我赶，紧咬比分。" },
            { id: 3, title: "跟随镜头来到比赛下半场，双方运动员迅速进入状态，以矫健熟练的动作迅速传球，直逼篮板。各位队员配合默契，抢断进取，比分僵持不下，在场观众无不为之热血沸腾，喝彩呐喊。最终，红队以3分之差率先赢得了比赛。" },
          ],

        }
      }else if (route.query.id == 33) {
        state.dataList = {
          title: "同心抗“疫”，感谢有你----致奋战在盛泰光电疫情防控一线全体勇士的感谢信",
          date: "2022-11-29",
          img_1: require('@/assets/news/33/1.png'),
          img_2: require('@/assets/news/33/2.png'),
          content_1: [
            { id: 1, title: "微光成炬，勇毅前行。面对突如袭来的疫情，大家闻令而动，主动请缨，冲锋一线，踊跃投身于疫情防控最前沿，用实际行动构筑起疫情防控的铜墙铁壁，充分展示了“团结奋斗、共克时艰”的盛泰精神。" },
            { id: 2, title: "身着防护服，手持采样管，医护人员冒着秋冬的冷风坚守一线，有条不紊地开展采样工作，勇做抗疫斗争的“先遣队”他们用行动诠释着白衣天使的责任与担当。" },
            { id: 3, title: "坚持外防输入，严把重点关口，公司安保人员驻守南门岗等重点区域，公共事务室负责在高速接送外来货物，严格查验过往车辆和司乘人员信息，做好防疫消毒工作，坚持做到不漏一车，不漏一人，从严从紧筑牢外防输入防线。" },
          ],
          content_2: [
            { id: 1, title: "疫情来得突然，生产不能停，职工的孩子也需要照顾，公司为有需求的职工家庭提供场地，保障职工驻场期间能兼顾孩子的生活起居和学习，把职工的需求放在第一位。特别感谢品质部、运营部、NB、供应链管理部、行政部这些单位的支援。" },
            { id: 2, title: "井然有序的“一米线”，主动出示的“健康码”，盛泰光电的职工们用自己的实际行动默默支持、无言付出，你们的理解和配合，就是我们工作的最大动力。" },
            { id: 3, title: "各部门落实保障生产所需的返厂人数，行政部紧急协调住宿和生活必需品，切实做好返厂人员的食宿保障工作，此外，疫情期间一日三餐的物资采购成为一大难题，公司餐厅主动出击，多方联系供应商，保证米面油菜肉蛋等必需物资供应，保证采购供应不断档，司机和保洁人员等行政部人员也在用餐高峰期到食堂进行支援，保障职工用餐有序进行。" },
          ],
          content_3: [
            { id: 1, title: "众志成城，高山可平；勠力同心，难关必克。广大的盛泰工作人员们，你们在战疫中挥洒的每一滴汗水、付出的每一份努力，我们会铭记在心，感恩于怀！最后，衷心祝愿你们和家人身体健康、工作顺利、阖家幸福、万事如意!" },
            ],
        }
      }else if (route.query.id == 34) {
        state.dataList = {
          title: "盛泰活动|守护双桥生态·绿色环保盛泰行 捡拾活动顺利举行",
          date: "2022-12-5",
          img_1: require('@/assets/news/34/1.png'),
          content_1: [
            { id: 1, title: "为进一步增强大家生态环境保护意识，动员全公司积极参与生态文明建设，树立不乱丢垃圾、保护生态环境的意识，巩固文明城市创建成果，共同建设美丽中国，盛泰光电科技股份有限公司开展了公益捡垃圾的公益活动，用实际行动倡导着文明理念“守护双桥生态，绿色环保盛泰行”。" },
          ],
          content_2: [
            { id: 1, title: "为进一步增强大家生态环境保护意识，动员全公司积极参与生态文明建设，树立不乱丢垃圾、保护生态环境的意识，巩固文明城市创建成果，共同建设美丽中国，盛泰光电科技股份有限公司开展了公益捡垃圾的公益活动，用实际行动倡导着文明理念“守护双桥生态，绿色环保盛泰行”。" },
            { id: 2, title: "在温暖阳光的照耀下，志愿者的身影成了一道亮丽的风景线。 " },
            { id: 3, title: "经过志愿者们的地毯式搜索，所到之处被拾捡得干干净净。集合时，每个人的手中都提着满满的一袋垃圾，经过志愿者们的辛勤劳动，个个战果累累。虽然都气喘吁吁，满头大汗，但没有一个人喊累，大家纷纷表示，能为环保事业做出自己的一份贡献，是十分乐意的事情。期待与你相约，我们下一次的活动。" },
          ],
        }
      }else if (route.query.id == 35) {
        state.dataList = {
          title: "秋招季|高校宣讲会圆满收官，场场精彩",
          date: "2022-12-22",
          img_1: require('@/assets/news/35/1.png'),
          content_1: [
            { id: 1, title: "2022的求职季如期而至，“求贤访才，虚位以待，成就梦想，“职”等你来！”成为金秋的招聘旋律，一起来回顾盛泰光电科技股份有限公司的校招现场吧！" },
            { id: 2, title: "经过前期紧张的筹备，2022年11月-12月，我们进行了四场校园招聘会：分别为重庆工程学院线下专场招聘会，重庆师范大学线上专场宣讲会，重庆高校联合线上宣讲会：包含重庆工商大学、重庆第二师范学院、重庆科技学院、重庆大学、重庆三峡学院，重庆电信职业学院线上专场宣讲会。" },
          ],
          content_2: [
            { id: 1, title: "首发站为重庆工程学院，招聘现场座无虚席，同学们热情高涨，为我们秋季校招开了个好头！尽管后期受疫情影响，只能通过线上的方式进行，但同学们求职的热情依旧不减，我们的校招最终取得了圆满成功。本次面向2023届毕业生秋季校招，我司共计收到简历210余份，经过初步面试，有40名同学进入复试阶段，又经公司高层领导、用人部门一对一面试后，最终有15名优秀应届生被我司录用！对比去年，除了之前紧密合作的重庆工程学院外，我们不断提升今年合作招聘目标院校，今年校招的重点主要是重庆本地二本及以上的高等院校，这也更符合我司未来业务发展需要，以及技术和管理团队对于人才的需求，为我司人才培养奠定了坚实基础。" },
            { id: 2, title: "秋季校招为我司未来发展注入了新鲜血液，增添了新的动力源，我们期待这群年轻人的到来，以及这些人带来的新的活力，海阔凭鱼跃，天高任鸟飞，愿他们在盛泰这片沃土上，学有所成，终有所长！" },
          ],
        }
      }else if (route.query.id == 36) {
        state.dataList = {
          title: "秋招季|高校宣讲会圆满收官，场场精彩",
          date: "2022-12-22",
          img_1: require('@/assets/news/36/1.png'),
          content_1: [
            { id: 1, title: "在盛泰光电科技股份有限公司生产车间，技术工人们正有条不紊地忙着手中的工作，COB芯片封装线等各道工序生产线高效运转，快马加鞭赶制订单。年关将至，从11月中旬开始，盛泰光电迎来订单量的“小高峰”。" },
          ],
          content_2: [
            { id: 1, title: "作为国内第一批手机摄像头模组制造商，盛泰光电专注CCM摄像模组的研发、制造、销售、服务，产品广泛应用于手机、平板、电脑、数码相机、汽车、医疗以及智能家居、智慧城市、航天航空等领域。" },
            { id: 2, title: " “我们目前的订单量在今年整个年度来讲，处于一个次高峰的状态，仅12月就接了不少的产品订单。”公司运营部总监梅龙龙说。为了抓生产、保订单，盛泰光电在做好疫情防控的同时，精细组织生产，全面梳理业务订单，合理排产，确保各产品、项目等生产任务按期保质保量完成，奋力冲刺全年各项目标任务。“现在单日产量可以达到50万颗。”梅龙龙说，订单小高峰将一直持续到明年1月上旬，接下来，公司还将通过招聘技术工人等措施，进一步提升产能。全国疫情防控政策调整，公司将继续开足马力、保质保量抓生产，同时，全力做好服务保障工作，稳产满产。" },
          ],
        }
      }
      else if (route.query.id == 52) {
        state.dataList = {
          title: "2023年第一季度IDL员工座谈会圆满结束",
          date: "2023-4-1",
          img_2: require('@/assets/news/52/1.png'),
          content_2: [
            { id: 1, title: "一年春作首，万事行为先。为进一步了解公司员工的工作情况和思想动态，让大家更好地参与到公司的管理和建设中，为2023年各项工作开好局、起好步，帮助大家迈出工作的“第一步”，盛泰光电于3月30日下午举办了2023年第一季度IDL员工座谈会，打造员工与公司领导面对面交流的平台。" },
            { id: 2, title: "2023年第一季度IDL员工座谈会在2#2楼培训室举行，本次座谈会邀请了入职半年内的IDL员工及各部门领导嘉宾，由人力资源部主持，首先领导发言，对参会员工表示诚挚的欢迎，希望通过本次座谈会，以面对面交流的方式与新员工们敞开心扉、真诚交流，站在2023年的新起点能共谋发展、再启新程。" },
          ],
          content_3: [
            { id: 1, title: "随后参会员工依次进行了自我介绍，进一步加深了大家对彼此的了解。游戏环节现场氛围热烈融洽，增强了员工的融入感，缓解了大家紧张的心情。随着座谈会逐步深入，大家畅所欲言，就停车场、员工培训、食堂菜品、产线系统等方面提出了自己的建议和问题，公司管理层就提出的建议和问题做了明确的解释和答复，部分员工也分享了来到盛泰后感受到的和谐融洽的团队氛围。" },
            { id: 2, title: "此次座谈会的圆满举办，不仅进一步搭建了公司与员工交流的平台，还展现了公司对人才的关怀重视，增强了新员工对公司的归属感。“等闲识得东风面，万紫千红总是春。”我们要保持谦逊的态度，养成终身学习的习惯，主动思考，要善于正视、发现问题，善于分析、解决问题，不断改进工作，努力实现个人进步。让我们以更饱满的热情昂首阔步、跑出加速度，为盛泰的高质量发展贡献自己的力量。" },
            ],
    
        }
      }
      else if (route.query.id == 53) {
        state.dataList = {
          title: "重庆市经信委金融处王逸飞处长到盛泰座谈交流",
          date: "2023-4-8",
          img_2: require('@/assets/news/53/1.png'),
          content_2: [
            { id: 1, title: "为进一步健全完善拟上市企业服务工作机制和重点项目金融服务工作，促进拟上市企业服务体系创新发展，扩展金融服务覆盖领域范围，有效助推拟上市企业成长和重点产业发展。" },
            { id: 2, title: " 2023年3月31日，由大足区经信委牵头，引领市经济信息委产业金融处、大足区金融中心领导一行到盛泰光电走访调研，同时组织金融服务工作座谈会，会议由重庆市金融处主要负责人王逸飞处长主持，市经济信息委产业金融处负责人、市中小企业发展服务中心副主任、市经济信息委产业金融处干部、重庆工业企业上市公益服务专家、重庆市专精特新基金投资负责人、大足区金融中心主要负责人、大足区经信委主要负责人、盛泰光电企业负责人参加座谈。" },
            ],
          content_3: [
            { id: 1, title: "会上，与会双方围绕了解企业当前经营业绩、治理结构、产品体系等方面情况，着力协调拟上市重点工业企业面临的财税法律、融资成本、要素保障等问题。深入进行了交流探讨。盛泰光电企业负责人介绍了集团公司发展历程，公司战略规划，以及下步公司金融服务实体经济和融入拟上市企业服务工作的发展思路。" },
            { id: 2, title: "会议认为，要充分抓住市经信委推进重点项目落地和盛泰光电在投融资领域的资源优势力量，进一步探索和建立市场主体梯度培育和金融服务工作机制，形成企业、项目、金融良性互动，助推产业招商，扶持带动产业发展。此次座谈会，为行业部门和拟上市企业务合作搭建了良好沟通平台，增进了双方的了解，为下一步工作指明了方向，也为推动拟上市企业服务模式改革创新和金融服务工作提出了新的要求，奠定了双方开启深度合作的良好基础。" },
            ],
        }
      }
      else if (route.query.id == 54) {
        state.dataList = {
          title: "群星之秀，川流不息——2023年第一季度“盛泰之星”颁奖仪式圆",
          date: "2023-4-19",
          img_2: require('@/assets/news/54/1.png'),
          content_1: [
            { id: 1, title: "4月19日下午，第一季度“盛泰之星”颁奖仪式在各部门领导及同事的掌声中落下帷幕。“盛泰之星”是公司授予员工的一枚闪亮勋章，也是员工个人职业生涯中一道耀眼的光环。本届“盛泰之星”通过人力资源部初审，各部门领导评选，总经理核定，重庆公司共诞生了6名获奖者。他们分别是一等奖运营部主管蒋晓隆、二等奖人力资源部罗其星、信息资讯部伍清红，三等奖移动终端研发工艺课NPI工程师张玉磊、行政部安全员陈英虎、运营部FOL-NB工程ME高级工程师张迁。" },
            { id: 2, title: "员工是企业的根基，优秀员工是企业的制胜法宝。正是多少个群星之秀，才换来了盛泰的川流不息，希望全体员工再接再厉，争创佳绩！携手盛泰共同成长！" },
          ],

        }
      }
      else if (route.query.id == 55) {
        state.dataList = {
          title: "大足区委书记陈一清率队观摩盛泰光电“5G＋工业互联网”项目",
          date: "2023-4-25",
          img_2: require('@/assets/news/55/1.png'),

          content_2: [
            { id: 1, title: "4月24日，大足区委书记陈一清率队观摩盛泰光电“5G＋工业互联网”项目。区委常委、办公室主任龙东阁、区委办公室、区改革促进中心副主任王尚英、副主任杨建、区委网信办主任冯梅、区科技局局长杜韧、区经济信息委主任晏大利、经开区经发局局长陈瑜、联通公司负责人等参加活动。" },
            { id: 2, title: "为了让一清书记一行人更好的了解盛泰光电公司5G+工业互联网智能制造国家级创新应用示范场景、数字经济产业园建设情况（包括芯片设计、封装、测试），在公司负责人带领下参观公司展厅、数字化生产车间、IT信息资讯部和可靠性实验室，公司负责人为领导们讲解了公司的发展历程、管理制度以及今后发展规划，与一清书记和大足联通公司王总亲切交流。一路走、一路看，一路议、一路赞。每到一处，书记认真听取盛泰光电负责人介绍5G+工业互联网智能制造国家级创新应用示范场景，亲身感受高质量发展，经济社会发展给企业带来的巨大变化。" },
            ],
          content_3: [
            { id: 1, title: "一清书记要求，要进一步提高对“5G+工业互联网”以及数字赋能建设的重视程度，主动研究“5G+工业互联网”实现规模发展的可行性和必要性，解放思想、开阔思路、创新举措，健全完善协调推进机制，创造性抓好各项工作落实，全力推动“5G+工业互联网”与实体经济在更广范围、更深层次、更高水平融合发展。" },
            { id: 2, title: "大足联通公司负责人汇报了盛泰光电“5G+工业互联网”、数字赋能建设工作。进一步推进“5G+工业互联网”应用，鼓励企业利用互联网平台开展研发设计、生产管理和运营优化，助力企业提质增效，实现企业从制造到“智”造的转型升级，为大足区经济高质量发展打造新引擎、注入新活力。" },
            ],
        }
      }
      else if (route.query.id == 56) {
        state.dataList = {
          title: "为未来深入合作奠定良好基础",
          date: "2023-5-24",
          img_2: require('@/assets/news/56/1.png'),

          content_2: [
            { id: 1, title: "5月23日上午，重庆康明斯发动机有限公司信息化部总监董江一行4人在联通公司大足分公司领导陪同下到盛泰光电科技股份公司参观交流，康明斯领导董江、陈建新、钟子愚、叶允锋，联通公司5G融合创新中心行业总监许刚、5G融合创新中心高级方案经理刁俊夫、5G融合创新中心行业客户经理谭丽雯、集团云网支撑专家韩春李，盛泰光电公司单位负责人热情接待并陪同参观交流。" },
            { id: 2, title: "在公司2号楼一楼展厅，董江一行听取了单位负责人关于盛泰公司生产经营、企业规划、发展历程等情况的介绍，并参观了展厅，对盛泰公司的产品、企业文化等相关情况进行了了解。当走到产品展柜面前，董江对盛泰公司小型化摄像头、业界最薄和最窄的笔电摄像头、像数达2亿的摄像头表现出了极大的兴趣，不仅走进驻足仔细观看，还主动发问，积极与单位负责人就产品应用、技术特点等展开了深入交流。" },
            ],
          content_3: [
            { id: 1, title: "随后，董江一行又先后参观SMT车间、EOL车间、信息资讯部，深入生产一线，隔着车间玻璃，近距离察看生产过程，详细了解生产技术、产能产量、现场管控等相关情况。并对盛泰光电整洁的生产环境、先进的生产技术、有序的生产流程、严格的质量管控等方面赞不绝口。" },
            {id:2,title:"参观结束后，双方高层管理人员在公司一楼会议厅召开了座谈交流会。双方围绕此行5G+专网应用5G应用场景展开了深入交流，并共同表示，希望未来加强交流，共同为幸福大足、美丽大足作出更多更大的贡献。"}
          ],
        }
      }
      else if (route.query.id == 57) {
        state.dataList = {
          title: "2023年“跃动篮球，绽放激情”盛泰光电夏季男子篮球赛完美收官！",
          date: "2023-5-25",
          img_2: require('@/assets/news/57/1.png'),
          content_2: [
            { id: 1, title: "5月23日，由公司举办的“跃动篮球，绽放激情”夏季篮球赛圆满落幕。公司各部门篮球爱好者踊跃报名参赛，群英荟萃，经过比赛的激烈角逐，上演了一场精彩纷呈的运动盛宴。“嘟——”随着裁判一声哨响，双方球员奋力一跃，为争夺球权拼尽全力，急停三分、抢断抄截，持球突破、巧妙包夹……激起了场边阵阵喝彩" },
            { id: 2, title: "双方球员你争我抢、你来我往，不断根据形势变更战术，利用防守、快攻制约对手，虽然体力急速下降，但球员们仍拼尽全力，坚持战斗到最后一秒。各代表队球员们严格执行比赛纪律，尊重对手、服从裁判，展现出优良的行为作风，体现出良好的体育道德风尚。" },
            { id: 3, title: " 经过激烈循环赛比拼，一队第一场以51：44的战胜二队；第二场以50：49的比分战胜三队，最终获得本次比赛的冠军。此次篮球赛，不仅是一次体育比赛，更是一次展现盛泰健儿团结拼搏的舞台。比赛中，球员们不断挑战自我，突破极限。未来，他们会以更加饱满的工作热情和更加健康的体魄投入到生活和工作中，以实际行动迎接盛泰光电重庆公司成立5周年的到来。" },
            ],
        }
      }
      else if (route.query.id == 58) {
        state.dataList = {
          title: "灯谜闹元宵·团圆在今宵，盛泰光电元宵节活动圆满结束",
          date: "2023-5-29",
          img_1: require('@/assets/news/58/1.png'),
          img_2: require('@/assets/news/58/2.png'),
          img_3: require('@/assets/news/58/3.png'),
          content_1: [
            { id: 1, title: "五年峥嵘岁月、五年坚守初心。五年前，共同的事业把我们凝聚到一起，一群人，一条心，一股劲，为着一个共同的目标集结出发。五年砥砺奋进、五年铸就辉煌。五年后，我们相约一起，乘着新时代的东风，追忆曾经奋斗的岁月，见证平凡而闪耀的过往，共同庆祝盛泰光电科技股份有限公司成立五周年。" },
            ],
          content_2: [
            { id: 1, title: "5月27日下午，盛泰光电科技股份有限公司在盛泰园区举办“腾云乘势，5限可能”五周年庆典，共同庆祝公司成立五周年，共同回顾盛泰光电重庆公司五年来勇于跨越的奋斗历程，共勉广大盛泰人接续奋斗、砥砺前行。伴随着欢快的音乐，渐渐拉开了仪式开场序幕，首先是公司副总经理孙总致辞：“五年的风雨兼程，我们荣辱与共，不断奋斗，不断前行；感谢过去五年各位优秀资深员工和家人对于盛泰光电成长的付出和努力。回首来时路，浓浓感恩情，谢谢我们各位员工、各位家人的不懈努力和坚持，在人生最美好的年华和盛泰光电一起成长；我我们共同度过最辛苦的日子；共同携手，团队协同，为了产品升级、紧急出货，为了设备搬迁、项目结案，为了抗击疫情、园区建设，为了美化家园、服务社区，在拼搏的路上都有我们每一位员工的身影，希望我们未来5年继续在一起，再接再厉、再创佳绩、努力奋斗、勇攀高峰。继续提升技术、优化制造、丰富产品，壮大民族光电产业，成为未来光电行业引领者。”" },
            { id: 2, title: "公司的稳步健康发展是所有员工风雨同舟、共同努力的必然结果，当中更是离不开一路陪伴、携手开创的老员工，他们是企业发展的基石。为此，公司借此庆典为入职盛泰五周年的员工授予特别定制的五周年定制银币，相信这份荣耀将伴随他们取得更大的成绩，在各自岗位中有更好的突破。" },
            ],
          content_3: [
            { id: 1, title: "运营部总监梅龙龙和公共关系部晏大琼作为五周年员工代表分享了他们入职五周年感言，让我们定格这个属于你们的荣耀时刻，记住今天，也记住你们与盛泰光电共同度过的这五年。" },
            { id: 2, title: "为提升员工的凝聚力和团结协作能力，增加五周年活动趣味性，活动最后精心准备了一场同心鼓趣味比赛，同心鼓比赛看似简单，实则极大地考验着团队协作能力，它需要大家在短时间内做出方向判断，并在行动过程中协调一致。游戏中大家灵活矫健，又专注同心，紧紧牵起的不仅是鼓绳，更是他们每个人团结一致、永不放弃、勇于拼搏的心" },
            { id: 3, title: "岁月不居，天道酬勤。在过去的五年中公司员工齐心协力，锐意进取，大家的辛勤工作，换来了丰硕的收获！展望未来，大家满怀憧憬，让我们携手共进，期待下一次的五年、乃至十年的聚首。" },
            ],
        }
      }
      else if (route.query.id == 59) {
        state.dataList = {
          title: "“民生情怀浓 惠民更有感”关爱大足困难残疾人公益活动",
          date: "2023-6-2",
          img_2: require('@/assets/news/59/1.png'),
          content_2: [
            { id: 1, title: "为了响应第三十三次全国助残日主题宣传及“民生情怀浓 惠民更有感”关爱大足困难残疾人公益，2023年5月26日，盛泰光电捐赠2万元给大足区残联，献爱心支持残疾人事业发展，盛泰光电代表团前往大足区特殊教育学校参加关爱大足困难残疾人公益募捐表彰活动。" },
            { id: 2, title: "活动于上午九点举行，现场到场有各单位以及社会各残疾人士、特殊教育学校的孩子及校长老师们。现场有小朋友们的手工作品，展现各自精湛的手艺，个个都是能工巧匠，充满了智慧与生机。活动最开始是颁奖环节，主持人为各爱心单位授予牌匾级证书。我司被评为“2023年助残爱心单位”并被授予该牌匾。在现场，我们参观了小朋友的现场表演。台上的小朋友虽然都是特殊人群，但是他们却通过自己的努力发出了耀眼的光芒，为我们带来了最精彩的表演。台下是老师们通过哑语及手势的指挥，每一个节目都让我看到了他们对生活的热爱，这就是我们献爱心，做公益的意义所在吧！" },
            { id: 3, title: "社会的爱，对于残疾人来说就像疲倦的人得以依靠；就像迷路的人，找到一盏明灯；就像炎热的人找到浓浓的绿荫；就像心灵干枯的人们找到一泓清澈山泉。通过本次活动弘扬志愿精神，对需要帮助的社会弱势群体给予帮助，传递爱的符音，让他们真切感受到社会的温暖关怀。 " },
            ],
        }
      }
      else if (route.query.id == 60) {
        state.dataList = {
          title: "【盛泰资讯】重庆市检察院检察长时侠联来盛泰公司调研",
          date: "2023-6-5",
          img_2: require('@/assets/news/60/1.png'),
          content_2: [
            { id: 1, title: "6月1日，重庆市检察院党组书记、检察长时侠联一行在大足区区委副书记、区政府区长徐晓勇、区委常委、政法委书记杨烈和区检察院党组书记、检察长孙琳的陪同下，来到盛泰光电进行调研，重庆市第六届人大代表、盛泰光电科技股份有限公司董事长赵伟进行热情接待。" },
            { id: 2, title: "在展厅，盛泰公司单位负责人详细介绍了公司发展历程和管理制度以及今后发展规划的相关情况。时侠联检察长一边听取介绍，一边询问相关产品的原料来源和应用领域。" },
            { id: 3, title: "在盛泰可靠性实验分析中心，时侠联检察长听取了盛泰单位负责人对摄像模组的生产工艺流程、主要产品相关情况的介绍，称赞道：“大足区政府能招商引资这样的一个实打实的企业，真是大足的褔气”。" },
            ],

        }
      }
      else if (route.query.id == 61) {
        state.dataList = {
          title: "观念转换，科学推进——2023重庆公司六西格玛项目顺利启动",
          date: "2023-6-13",
          img_1: require('@/assets/news/61/1.png'),
          img_2: require('@/assets/news/61/2.png'),
          content_1: [
            { id: 1, title: "六西格玛是一种系统的、以客户为中心的，基于事实和数据的企业管理方法论。根据公司战略目标，为了稳定而高效地满足顾客需求，促进精益生产，带动质量大幅提高、成本大幅度降低，实现企业竞争力的突破，重庆工厂端HR协同运营部，特邀外训机构专业老师张峰作为讲师，指导六西格玛项目培训。此项目2023年6月初启动，将持续半年时间至2024年初完成。6月9日-6月10日，公司顺利开展倡导者启动会，并完成D阶段理论知识的培训。" },
            ],
          content_2: [
            { id: 1, title: "启动会为期一天，在正式开始前，由运营部副总经理李响林作为倡导者代表进行发言，呼吁号召各部门管理者积极动员希望通过此项培训，采用科学的方法，进一步提高顾客满意度，降低组织的资源成本。李总的发言很快与大家达成共识。启动会中，张老师从六西格玛理论知识和项目实施流程入手，主要介绍了六西格玛培训的必要性、意义、优势和关键概念；重点针对界定阶段的任务目标和常见问题进行详细讲解，包括认识及理解活动角色与责任、投入及时间分配、人员选择、收益构成与计算等。老师通过系列案例与现场互动充分调动各成员的学习兴趣，有效地增进了各学员对知识点的理解。" },
            ],
            content_3: [
              {id:1,title:"经过老师的指导及建议，学员们结合自身能力及强项，共组建8个小组，12个项目，小组成员涵盖制造、PE、ME、IE、品质等部门的核心人才。各小组选定各自项目并按规定完成项目的规划、分析及报告，为后续阶段的计划实施做好充足准备"},
              {id:2,title:"在6月10日的D阶段理论知识学习中，张老师展开叙述了客户之声VOC和项目定义的相关内容，介绍并分析树形图、亲和图等工具的使用时机、优缺点、注意事项、步骤及范例，以及如何运用5W2H、SMART评估等方法。通过把运行数据作为分析和解决问题的关键依据，六西格玛让各学员在日常工作中初步养成精细管理的意识和思维，形成注重事实和数据的氛围；通过实施项目，学员对项目流程采用量化的方法分析影响因素，充分运用六西格玛的一系列工具，按照由外及内、由粗到细、层层过筛、层层剥茧的思路，找出最关键的因素加以改进从而达到更高的客户满意度。"},
              {id:3,title:"六西格玛目前已经被广泛引入到许多世界领先的公司，六西格玛方法已经成为企业管理的基本工具，只有企业中掌握方法、理解理念的人多了，才能真正使企业有脱胎换骨的提升。期待项目的各小组成员通过理论培训及项目辅导，将成果转化为公司实际的项目运作，真正地将六西格玛的改善收益完美体现，为企业做大、做强的愿景做出贡献。"},
            ]
        }
      }
      else if (route.query.id == 62) {
        state.dataList = {
          title: "【盛泰团建】打造优秀团队，共创盛泰辉煌",
          date: "2023-6-5",
          img_1: require('@/assets/news/62/1.png'),
          img_2: require('@/assets/news/62/2.png'),
          content_1: [
            { id: 1, title: "为进一步增强团队凝聚力，有效促进同事间的相互了解和团队合作精神，重庆盛泰光电移动终端事业部研发、PM、自动化三个部门联合组织开展了以“打造优秀团队，共创盛泰辉煌”为主题的团建活动。让小伙伴们在忙碌的工作之余相聚在一起，劳逸结合，放松心情，感受大自然的魅力，欢享初夏的美好时光。" },
            { id: 2, title: "工作全力以赴，生活不可辜负。6月3日早上9点半，小伙伴们轻装出行，结伴前往玉龙镇宁翠山庄，开启了今天的团建活动。首先是主持人带领大家做个“花开花落”的破冰游戏，让大家把所有疲惫抛之脑后，所有烦恼也随之烟消云散。先热热身，为后面的比赛游戏做准备……" },
            ],
          content_2: [
            { id: 1, title: "同心鼓游戏考验的是团队的合作的能力，情感凝聚，思想统一，行动一致。心往一处想，力往一处使才能取得胜利。踩气球游戏利用个人或团体对抗的方式，以增加小队间的灵活性，活动中队员们可谓是八仙过海各显神通，使出浑身解数只为团队胜利。蒙眼取水游戏把整场活动推进了高潮，场面一度失控，队员们为了自己队能取得胜利而努力拼博着。“蒙眼取水”充分的展现了团队的默契度与信任度，相信自己的团队不被外界所干扰，旨在提高团队的协作力，当队员有走“偏”时应予以及时制止纠正，因为是同一个团队。扑克牌运面粉中，大家都不怕面粉弄花了脸，人人都勇往直前敢于挑战，虽然面粉弄花了脸，但是喜悦和兴奋之情溢于言表。运面粉也不是靠某一个人就能取得胜利，而是要大家齐心协力。话说一人强，不是强，再强也是一只羊；团队强，才是强，团结起来就是只狼。只有团结才有强大的能量，凝聚在一起才是团队。" },
            { id: 2, title: "最好的生活方式，是和一群志同道合的人，一起奔跑在理想的路上，回头有一路的故事，低头有坚定的脚步，抬头有清晰的远方。加油吧，盛泰人，末来可期" },
            ],
        }
      }
      else if (route.query.id == 63) {
        state.dataList = {
          title: "“重庆市企业创新奖”出炉，盛泰光电科技股份有限公司榜上有名",
          date: "2023-6-17",
          img_2: require('@/assets/news/63/1.png'),
          content_2: [
            { id: 1, title: "近日，重庆市企业创新奖出炉，盛泰光电科技股份有限公司榜上有名。重庆市企业创新奖评选工作由市经济信息委、市人力社保局、市商务委、市文化旅游委联合开展。经逐级推荐遴选，在广泛征求意见、集体研究的基础上，确定了150家企业为重庆市企业创新奖拟表彰对象。" },
            { id: 2, title: "企业是科技创新的主体，也是推动创新创造的生力军。近年来，双桥经开区大力实施创新驱动核心战略，优化完善科技创新体制机制，加快平台打造、人才引进、项目申报、成果转化等工作进度，科技创新综合实力实现了新的跃升，为高质量发展注入新动力。盛泰光电也一定会把握好政府创设的优越营商环境，奋勇拼搏赢未来，为大足经济社会发展作出新的贡献。" },
            ],

        }
      }
      else if (route.query.id == 64) {
        state.dataList = {
          title: "【盛泰资讯】重庆市政协主席唐方裕莅临盛泰光电科技股份有限公司参观调研",
          date: "2023-6-25",
          img_2: require('@/assets/news/64/1.png'),
          content_2: [
            { id: 1, title: "2023年6月20日下午，重庆市政协主席唐方裕，在大足区区委书记陈一清、大足区政协主席廖文丽、经开区党工委副书记周树云等领导陪同下莅临盛泰光电科技股份有限公司参观调研，公司高层领导进行了热情的接待。" },
            { id: 2, title: "参观调研时，一清书记对大足的发展战略规划，企业亮点做了详细讲解，市政协唐主席此行调研川渝毗邻地区协同发展情况时强调，要认真落实川渝两省市毗邻地区合作共建区域发展功能平台要求，统筹推进基础设施、产业发展、公共服务等领域合作，为双城经济圈高质量发展提供重要支撑。" },
            { id: 3, title: "参观调研中，市政协唐方裕主席关切询问产品应用领域、市场占有率等情况。他希望企业抓住成渝地区打造的两大万亿级优势产业集群的机遇，发挥自身关键零部件上的技术优势，进一步把企业做大做强。" },
            { id: 4, title: "参观结束后，区委区府领导们肯定了企业近年来为全区和全市稳增长做出的重要贡献，希望企业要加大技改投入，坚定发展信心，继续扎根重庆，争取长期可持续发展。" },
            ],
        }
      }
    })
    return {
      ...toRefs(state), ...methods
    }
  }
}
</script>

<style scoped lang="less">
.p_1 {
  color: #939393;
  font-size: 17px;
  margin-top: 30px;
  text-align: center;
}

.content p:nth-child(4) {
  margin-top: 60px;
}

.content img {
  margin: 30px auto;
  width: 60%;
  height: auto;
  display: table-cell
}

.box {
  width: 100%;

  p {
    color: #666;

  }

  .p_01 {
    font-size: 18px;
    margin-top: 30px;
    text-indent: 0;
  }

  .img_1,
  .img_2 {
    cursor: pointer;
    width: 50px;
    height: 50px !important;
    height: 100%;
    margin: 0;
  }

  .img_2 {
    margin-left: 15px;
  }

  .content {
    margin: 0 auto;
    width: 70%;
    min-height: 100px;
    max-width: 1920px;

    h1 {
      text-align: center;
      margin-top: 50px;
    }
  }
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px
}

.divI {
  margin-top: 50px;
}



.span_1 {
  position: relative;
  width: 55px;
  height: auto;
  font-size: 40px;
  color: rgb(102, 102, 102);
  cursor: pointer;
  display: inline-block;
}

.span_2 {
  font-size: 48px;
  color: rgb(102, 102, 102);
  cursor: pointer;
  margin-left: 10px;
}

.span_1:hover,
.span_2:hover {
  color: rgb(51, 51, 51);
}

.qrcode {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100px;
  min-height: 100px;
  padding: 10px;
  box-shadow: 0 2px 10px #aaa;
  box-sizing: border-box;
  background-color: #fff;
  // border: 1px solid #ccc;
  display: block;
  display: flex;
  flex-direction: column-reverse;

  p {
    text-align: center;
    font-size: 14px;
    margin: 5px 0;
    padding: 0;
  }
}

.qrcode::before {
  content: '';
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  width: 0;
  height: 0;

  border-bottom: 5px solid #ddd;
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    position: relative;
    display: block;
    .shade{
    position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 10;
    }
    .diaLog{
      position: fixed;
      top: 50%;
      transform: translate(-50%,-50%);
      left: 50%;
      background: #fff;
      width: 900px;
      height: 700px;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
      padding: 70px 30px 0px 30px;
      box-sizing: border-box;
      text-align: center;
      z-index: 99;
      h3{
        margin-top: 20px;
        font-size: 60px;
      }
      p{
        font-size: 45px;
        margin-top: -30px;
      }
      .I{
        margin: 30px 0;
        font-size: 45px;
        color: #1f8dd6 ;
        cursor: pointer;
      }
    }
    h2 {
      font-size: 80px;
    }

    .center {
      text-align: left;
      h2{
        text-align: center;
        width: 80%;
        margin: 50px auto;
      }
      .textImg{
        padding: 0 30px;
        box-sizing: border-box;
        p{margin: 50px 0;font-size: 60px;}
        img{margin: 50px 0;width: 100%;}
      }
      .p_1 {
        font-size: 40px;
      }
      .divI{
        margin: 100px auto;
        width: 80%;
        #qrcode{
          width: 500px;
        }
        .span_1,.span_2{
          font-size: 100px;
        }
        .span_2{
          margin-left: 100px;
          font-size: 120px;
        }
      }
    }
  }
  
}

</style>

<style>

</style>